<template>
  <div class="container mescroll-touch" id="toTop">
    <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
      <!-- <div class="banner">
        <img class="bannerImg" src="https://fx.tv-conf.com/Api/UploadImg/SkillTraining_Img/Img_jnzb.png" />
        <img class="goBack" src="@/assets/images/back_comm_icon@2x.png" @click="$router.go(-1)" />
      </div> -->
      <div class="banner" v-if="bannerList.length > 0">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#CA001B">
          <van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="imgclick(item.UrlLink)">
            <img class="bannerImg" :src="item.File_Url" />
          </van-swipe-item>
        </van-swipe>
        <img class="goBack" src="@/assets/images/back_comm_icon@2x.png" @click="$router.go(-1)" />
      </div>
      <div class="classify">
        <span :class="{'active': ModuleType == 6}" @click="selectClassify(6)">精彩回放</span>
        <span :class="{'active': ModuleType == 5}" @click="selectClassify(5)">直播预约</span>
        <span :class="{'active': ModuleType == 4}" @click="selectClassify(4)">正在直播</span>
      </div>
      <div id="dataList">
        <div class="list" v-for="(item, index) in list" :key="index" @click="toContentPage(item.Id, item.Type)">
          <div class="cover">
            <img class="coverImg" :src="item.Thumbnail" />
            <img class="playIcon" src="@/assets/images/play_comm_icon@2x.png" />
          </div>
          <div class="detail">
            <p class="detail_title">{{item.Title}}</p>
            <p class="detail_speaker" v-if="item.Speaker">讲者：{{item.Speaker}}</p>
            <p class="detail_hospital" v-if="item.HospitalName">医院：{{item.HospitalName}}</p>
            <div class="detail_nums">
              <div class="nums">
                <img src="@/assets/images/browse_comm_icon@2x.png" />
                <span>{{item.WatchCount}}</span>
              </div>
                <div class="nums">
                  <img src="@/assets/images/like_comm_icon@2x.png" />
                  <span>{{item.LikeCount}}</span>
                </div>
            </div>
          </div>
        </div>
      </div>
    </mescroll-vue>
  </div>
</template>

<script>
import MescrollVue from 'mescroll.js/mescroll.vue'

export default {
  name: 'SkillTrainingLiveList',
  data() {
    return {
      bannerList: [],
      ModuleType: 6,
      list: [],
      PageIndex: 0,
      PageSize: 6,
      mescroll: null, // mescroll实例对象
      mescrollDown:{
        use: false,
      },
      mescrollUp: { // 上拉加载的配置.
        isBounce: false,
			  callback: this.upCallback, // 上拉回调
				page: {
					num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
					size: 6 //每页数据条数,默认10
				},
        noMoreSize: 0,
				htmlNodata: '<p class="upwarp-nodata">-- END --</p>',
        toTop: {
          warpId : 'toTop',
          src: './static/image/top_comm_icon@2x.png' // 回到顶部按钮的图片路径,支持网络图
        },
        empty: {
          // 列表第一页无任何数据时,显示的空提示布局; 需配置warpId才生效;
          warpId: 'dataList', // 父布局的id;
          icon: './static/image/none_comm_png@2x.png', // 图标,支持网络图
          tip: '敬请期待', // 提示
        },
			},
    }
  },
  components: {
    MescrollVue
  },
  computed: {
    code() {
      return this.$route.query.code;
    },
  },
  activated(){
    this.mescroll.setBounce(false);
    this.mescroll.scrollTo(this.mescroll.preScrollY, 0);
  },
  deactivated() {
    this.mescroll.setBounce(true);
  },
  created() {
    this.$store.commit('changeNavbarIndex', 1);
    this.getBanner();
  },
  methods: {
    // 获取Banner
    getBanner() {
      let arr = this.code.split('|');
      let code = arr[arr.length - 2];
      this.$axios.get('/Api/api/Web/ImgFile/GetImgFileListByCode?Code=' + code).then(res => {
        this.bannerList = res.Data;
      })
    },
    // mescroll组件初始化的回调,可获取到mescroll对象
		mescrollInit (mescroll) {
			this.mescroll = mescroll  // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
		},
    // 上拉回调
		upCallback (page) {
      this.getList(page.num, page.size);
		},
    selectClassify(ModuleType) {
      this.ModuleType = ModuleType;
      this.list = [];
      this.mescroll.resetUpScroll() // 刷新列表数据
    },
    // 获取列表数据
    getList(PageIndex, PageSize) {
      this.PageIndex = PageIndex;
      this.PageSize = PageSize;
      setTimeout(() => {
        this.$axios.post('/Api/Api/Web/Article/GetArticleList', {
          SubjectTCode: this.code,
          ModuleType: this.ModuleType,
          PageIndex: this.PageIndex,
          PageSize: this.PageSize
        }).then((res) => {
          let arr = res.Data.List;
          // 如果是第一页需手动置空列表
          if (PageIndex === 1) this.list = []
          // 把请求到的数据添加到列表
          this.list = this.list.concat(arr);
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            this.mescroll.endSuccess(arr.length)
          })
        }).catch((e) => {
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          this.mescroll.endErr()
        })
      }, 200);
    },
    toContentPage(Id, Type) {
      this.$router.push({
        path: 'contentPage',
        query: {
          id: Id,
          type: Type
        }
      });
    },
    imgclick(UrlLink) {
      let objExp=new RegExp(/^(f|ht)tps?:\/\//i);
      if(UrlLink) {
				if(objExp.test(UrlLink)) {
				  location.href = UrlLink;
				}else{
					this.$router.push(UrlLink)
				}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  background-color: #F9F9F9;
  .mescroll {
    position: fixed;
    top: 0;
    bottom: 60px;
    height: auto;
    padding: 15px 12px;
    box-sizing: border-box;
    // .banner {
    //   position: relative;
    //   border-radius: 10px;
    //   margin-bottom: 15px;
    //   overflow: hidden;
    //   .bannerImg {
    //     display: block;
    //     width: 100%;
    //   }
    //   .goBack {
    //     position: absolute;
    //     top: 10px;
    //     left: 10px;
    //     width: 30px;
    //     height: 30px;
    //   }
    // }
    .banner {
      position: relative;
      border-radius: 10px;
      margin-bottom: 15px;
      overflow: hidden;
      .bannerImg {
        width: 100%;
        display: block;
      }
      .goBack {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 30px;
        height: 30px;
      }
    }
    .classify {
      margin-bottom: 10px;
      span {
        font-size: 15px;
        color: #333333;
        margin-right: 20px;
      }
      .active {
        color: #CA001B;
        font-weight: bold;
      }
    }
    .list {
      display: flex;
      height: 115px;
      border-radius: 4px;
      margin-bottom: 10px;
      background-color: #FFFFFF;
      overflow: hidden;
      .cover {
        position: relative;
        width: 171px;
        .coverImg {
          width: 100%;
          height: 100%;
        }
        .playIcon {
          position: absolute;
          left: 50%;
          top: 50%;
          width: 30px;
          height: 30px;
          transform: translate(-15px, -15px);
        }
      }
      .detail { 
        position: relative;
        flex: 1;
        width: 0;
        padding: 8px;
        .detail_title {
          font-size: 14px;
          color: #333333;
          overflow: hidden;
          text-overflow:ellipsis;
          display:-webkit-box;
          -webkit-box-orient:vertical;
          -webkit-line-clamp:2;
          word-wrap: break-word;
          margin-bottom: 4px;
        }
        .detail_speaker {
          font-size: 12px;
          color: #666666;
          line-height: 21px;
        }
        .detail_hospital {
          font-size: 12px;
          color: #999999;
          line-height: 21px;
        }
        .detail_nums {
          position: absolute;
          bottom: 6px;
          display: flex;
          height: 20px;
          .nums {
            display: flex;
            align-items: center;
            margin-right: 6px;
            img {
              width: 12px;
              margin-right: 2px;
            }
            span {
              font-size: 11px;
              color: #666666;
            }
          }
        }
      }
    }
  }
}
</style>
